<template>
  <router-view/>
</template>


<style>
body {
  font-family: 'Roboto', sans-serif;
  background-color : rgba(128, 128, 128, 0.119);
}
</style>