import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/admin',
    name: 'admin',
    // component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
    children: [
      {
        path: 'home',
        name: 'admin_home',
        component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
      },
      {
        path: 'commande',
        name: 'commande',
        // component: () => import(/* webpackChunkName: "about" */ '../components/admin/Commande.vue')
      },
      {
        path: 'ordonnance',
        name: 'ordonnance',
        component: () => import(/* webpackChunkName: "about" */ '../views/OrdonnanceView.vue'),
      },
      {
        
        path: ':id',
        name: 'ordonnance-detail',
        component: () => import(/* webpackChunkName: "about" */ '../views/DetailOrdonnanceView.vue'),
        props: true
      
      },
      {
        path: 'commande',
        name: 'commande',
        component: () => import(/* webpackChunkName: "about" */ '../views/CommandeView.vue')
      },
      {
        path: 'comptabilite',
        name: 'comptabilite',
        // component: () => import(/* webpackChunkName: "about" */ '../components/admin/Comptabilite.vue')
      },
      {
        path: 'monitoring',
        name: 'monitoring',
        // component: () => import(/* webpackChunkName: "about" */ '../components/admin/Monitoring.vue')
      },
      {
        path: 'archive',
        name: 'archive',
         component: () => import(/* webpackChunkName: "about" */ '../views/ArchiveView.vue')
      },
      {
        path: 'categorie_product',
        name: 'categorie_product',
         component: () => import(/* webpackChunkName: "about" */ '../views/CategorieProductView.vue')
      },
      {
        path: 'product',
        name: 'product',
         component: () => import(/* webpackChunkName: "about" */ '../views/ProductView.vue')
      },
      {
        path: 'slidershop',
        name: 'slidershop',
         component: () => import(/* webpackChunkName: "about" */ '../views/SliderShopView.vue')
      },
      {
        path: 'commande_product',
        name: 'commande_product',
         component: () => import(/* webpackChunkName: "about" */ '../views/CommandeProductView.vue')
      },
      {
        path: 'utilisateur',
        name: 'utilisateur',
        component: () => import(/* webpackChunkName: "about" */ '../views/UsersTable.vue')
      },

      {
        path: 'createnewuser',
        name: 'createnewuser',
        component: () => import(/* webpackChunkName: "about" */ '../views/CreateProfilUserView.vue')
      },

    ]
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/inscription',
    name: 'signup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SignUpView.vue')
  },
  {
    path: '/',
    name: 'checking',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CheckingView.vue')
  }
]

const router = createRouter({
  base: '',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
