import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import 'preline'
import VueApexCharts from "vue3-apexcharts";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { baseUrl } from '@/Constant/Config'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const app = createApp(App)
.use(store)
.use(router)
.use(VueApexCharts)
.use(Toast)
.use(VueSweetalert2)
.mount('#app');
